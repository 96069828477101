<template>
    <custom-table
        :runModeServer="false"
        :title="$t('pages.module.zoom.meeting.detail.registrant.title')"
        :subTitle="$t('pages.module.zoom.meeting.detail.registrant.subTitle')"
        selectableRows
        rowKey="id"
        :items="table.data"
        :loading="table.loading"
        :columns="fields"
        :actions="actions"
        :pagination="table.pagination"
        @action="handleClickAction"
        @selectableRows="handleSelectedRow">
        <template v-slot:user="{ row: record }">
            <router-link :to="'/ecommerce/user?userID=' + record.user.id" class="cursor-pointer text-gray-600 text-hover-primary" target="_blank">
                <span class="fw-bolder">{{ record.user.full_name }}</span>
            </router-link>
            <br>
            <a :href="'mailto:' + record.user.email" class="text-gray-600 text-hover-primary mb-1 cursor-pointer">
                {{ record.user.email }}
            </a>
        </template>
        <template v-slot:createdAt="{ row: record }">
            {{ $moment(record.created_at).format("DD.MM.YYYY - HH:mm:ss") }}
        </template>
        <template v-slot:actions="{ row: record }">
            <div class="d-flex justify-content-end">
                <el-tooltip :content="$t('pages.module.zoom.meeting.detail.registrant.copyJoinUrl')" placement="top">
                    <a v-on:click="copyJoinUrl(record)" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                        <span class="svg-icon svg-icon-3">
                            <inline-svg src="/media/icons/duotune/coding/cod007.svg" />
                        </span>
                    </a>
                </el-tooltip>
                <el-popconfirm :title="$t('messages.sureDelete')" :confirm-button-text="$t('btn.yes')" :cancel-button-text="$t('btn.no')" @confirm="deleteRecord([record.id])">
                    <template #reference>
                        <a class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                            <span class="svg-icon svg-icon-3">
                                <inline-svg src="/media/icons/duotune/general/gen027.svg"/>
                            </span>
                        </a>
                    </template>
                </el-popconfirm>
            </div>
        </template>
    </custom-table>
</template>

<script>
import CustomTable from "@/components/custom-table";

export default {
    name: "Registrant",
    props: ['meetingID'],
    components: {
        CustomTable
    },
    data() {
        return {
            fields: [
                {
                    name: this.$t("common.id"),
                    key: "id",
                    class: ""
                },
                {
                    name: this.$t("pages.module.zoom.meeting.detail.registrant.cols.user"),
                    scopedSlots: {customRender: "user"}
                },
                {
                    name: this.$t("common.createdAt"),
                    scopedSlots: {customRender: "createdAt"}
                },
                {
                    name: this.$t("common.action"),
                    key: "action",
                    scopedSlots: {customRender: "actions"}
                }
            ],
            actions: [
                {
                    name: "refresh",
                    icon: "bi-arrow-repeat",
                    label: "btn.refresh"
                },
                {
                    name: "delete",
                    icon: "bi-trash",
                    label: "btn.delete"
                }
            ],
            table: {
                loading: false,
                data: [],
                pagination: {
                    current: 1,
                    pageSize: 10,
                    total: 0,
                }
            },
            meeting: {},
            selectedRowKeys: [],
        }
    },
    created(){
        this.loadMeeting();
    },
    methods: {
        loadMeeting() {
            this.table.loading = true;

            this.axios.get(this.endpoints['module_zoom_meeting'] + '/' + this.meetingID).then((response) => {
                let data = response.data.data;
                this.meeting = data;

                this.table.data = data.registrants ?? [];
                this.table.pagination.total = this.table.data.length;
            }).finally(()=> {
                this.table.loading = false;
            })
        },
        handleClickAction(name) {
            switch (name) {
                case "refresh":
                    this.loadMeeting();
                    break;

                case "delete":
                    this.deleteRecord(this.selectedRowKeys.flat());
                    break;

                default:
                    break;
            }
        },
        deleteRecord(id){
            this.$store.dispatch("module/zoom/meeting/registrant/delete", {
                id: id
            }).then((successDeleted) => {
                this.selectedRowKeys = this.selectedRowKeys.filter(x => !successDeleted.includes(x));
                if(successDeleted) {
                    this.loadMeeting();
                }
            });
        },
        handleSelectedRow(record){
            this.selectedRowKeys = record;
        },
        copyJoinUrl(record){
            navigator.clipboard.writeText(record.join_url).then(() => {
                this.$notify({
                    type: 'success',
                    title: this.$t("messages.success"),
                    message: this.$t("messages.copyOk"),
                });
            });
        }
    }
}
</script>

<style>

</style>