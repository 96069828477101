<template>
    <div class="d-flex flex-wrap flex-stack my-5">
        <h2 class="fs-2 fw-bold my-2">
            {{ sprintf($t('pages.module.zoom.meeting.detail.titlePattern'), [meeting.topic]) }}
        </h2>

        <div>
            <router-link to="/module/zoom/meeting" class="btn btn-primary align-self-center">
                <span class="svg-icon svg-icon-2">
                    <inline-svg src="/media/icons/duotune/arrows/arr021.svg"/>
                </span>
                {{ $t("pages.module.zoom.meeting.title") }}
            </router-link>
            <a v-on:click="showMeetingRecording(record)" class="btn btn-primary align-self-center ms-3" :data-kt-indicator="meetingRecordingLoading ? 'on' : null" :class="meetingRecordingLoading && 'disabled'">
                <span class="svg-icon svg-icon-3" v-if="!meetingRecordingLoading">
                    <inline-svg src="/media/icons/duotune/general/gen005.svg" />
                </span>
                <span v-if="meetingRecordingLoading" class="indicator-progress">
                    <span class="spinner-border spinner-border-sm align-middle me-2"></span>
                </span>
                {{ $t("pages.module.zoom.meeting.recording.title") }}
            </a>
        </div>
    </div>

    <el-tabs v-model="tabModel" class="paragraph-tabs">
        <el-tab-pane :label="$t('pages.module.zoom.meeting.detail.tabs.generalDetails')" name="general">
            <el-form class="meetingForm">
                <div class="row g-6 mb-6 g-xl-9 mb-xl-9">
                    <div class="col-md-8">
                        <div class="card">
                            <div class="card-body d-flex flex-column p-9">
                               <div class="row">
                                   <div class="col-md-6 fv-row mb-1">
                                       <div class="fv-row mb-1">
                                           <label class="fs-6 fw-bold mb-2">{{ $t('pages.module.zoom.meeting.cols.topic') }}:</label>
                                           <el-form-item>
                                               <span class="fw-bold text-gray-700">{{ meeting.topic }}</span>
                                           </el-form-item>
                                       </div>
                                       <div class="fv-row mb-1">
                                           <label class="fs-6 fw-bold mb-2">{{ $t('pages.module.zoom.meeting.detail.cols.uuid') }}:</label>
                                           <el-form-item>
                                               <span class="fw-bold text-gray-700">{{ meeting.uuid }}</span>
                                           </el-form-item>
                                       </div>
                                   </div>
                                   <div class="col-md-6 fv-row mb-1">
                                       <div class="fv-row mb-1">
                                           <label class="fs-6 fw-bold mb-2">{{ $t('pages.module.zoom.meeting.detail.cols.remoteHostID') }}:</label>
                                           <el-form-item>
                                               <span class="fw-bold text-gray-700">{{ meeting.remote_host_id }}</span>
                                           </el-form-item>
                                       </div>
                                       <div class="fv-row mb-1">
                                           <label class="fs-6 fw-bold mb-2">{{ $t('pages.module.zoom.meeting.cols.password') }}:</label>
                                           <el-form-item>
                                               <span class="fw-bold text-gray-700">{{ meeting.password }}</span>
                                           </el-form-item>
                                       </div>
                                   </div>
                                   <div class="fv-row mb-1">
                                       <label class="fs-6 fw-bold mb-2">{{ $t('pages.module.zoom.meeting.cols.agenda') }}:</label>
                                       <el-form-item>
                                           <span class="fw-bold text-gray-700">{{ meeting.agenda }}</span>
                                       </el-form-item>
                                   </div>
                                   <div class="fv-row mb-1">
                                       <label class="fs-6 fw-bold mb-2">{{ $t('pages.module.zoom.meeting.detail.cols.startUrl') }}:</label>
                                       <el-form-item>
                                           <a :href="meeting.start_url" class="fw-bold text-gray-700 text-hover-primary cursor-pointer" target="_blank">
                                               {{ meeting.start_url }}
                                           </a>
                                       </el-form-item>
                                   </div>
                                   <div class="fv-row mb-1">
                                       <label class="fs-6 fw-bold mb-2">{{ $t('pages.module.zoom.meeting.detail.cols.joinUrl') }}:</label>
                                       <el-form-item>
                                           <a :href="meeting.join_url" class="fw-bold text-gray-700 text-hover-primary cursor-pointer" target="_blank">
                                               {{ meeting.join_url }}
                                           </a>
                                       </el-form-item>
                                   </div>
                               </div>
                            </div>
                        </div>
                        <!--<div class="card mt-7" v-if="meeting.host && Object.keys(meeting.host)">
                            <div class="card-header">
                                <div class="card-title pt-4 pb-4">
                                    <h3 class="card-title align-items-start flex-column">
                                        <span class="card-label fw-bolder fs-3 mb-1">{{ $t('pages.module.zoom.meeting.detail.host.title') }}</span>
                                    </h3>
                                </div>
                            </div>
                            <div class="card-body d-flex flex-column p-9">
                                <div class="row">
                                    <div class="col-md-6 fv-row mb-1">
                                        <div class="fv-row mb-1">
                                            <label class="fs-6 fw-bold mb-2">{{ $t('pages.module.zoom.host.cols.host') }}:</label>
                                            <el-form-item>
                                                <span class="fw-bold text-gray-700">{{ meeting.host.host }}</span>
                                            </el-form-item>
                                        </div>

                                        <div class="fv-row mb-1">
                                            <label class="fs-6 fw-bold mb-2">{{ $t('pages.module.zoom.host.cols.apiKey') }}:</label>
                                            <el-form-item>
                                                <span class="fw-bold text-gray-700">{{ meeting.host.api_key }}</span>
                                            </el-form-item>
                                        </div>
                                    </div>
                                    <div class="col-md-6 fv-row mb-1">
                                        <div class="fv-row mb-1">
                                            <label class="fs-6 fw-bold mb-2">{{ $t('pages.module.zoom.host.cols.apiSecret') }}:</label>
                                            <el-form-item>
                                                <span class="fw-bold text-gray-700">{{ meeting.host.api_secret }}</span>
                                            </el-form-item>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>-->
                    </div>
                    <div class="col-md-4">
                        <div class="card">
                            <div class="card-body d-flex flex-column p-9">
                                <div class="fv-row mb-1">
                                    <label class="fs-6 fw-bold mb-2">{{ $t('pages.module.zoom.meeting.cols.meetingID') }}:</label>
                                    <el-form-item>
                                        <span class="fw-bold text-gray-700">{{ meeting.meeting_id }}</span>
                                    </el-form-item>
                                </div>
                                <div class="fv-row mb-1">
                                    <label class="fs-6 fw-bold mb-2">{{ $t('pages.module.zoom.meeting.cols.meetingType') }}:</label>
                                    <el-form-item>
                                        <span class="fw-bold text-gray-700">{{ meeting.meeting_type && meeting.meeting_type.name }}</span>
                                    </el-form-item>
                                </div>

                                <div class="fv-row mb-1" v-if="meeting.host && Object.keys(meeting.host)">
                                    <label class="fs-6 fw-bold mb-2">{{ $t('pages.module.zoom.host.cols.host') }}:</label>
                                    <el-form-item>
                                        <span class="fw-bold text-gray-700">{{ sprintf("%s (%s)", [meeting.host.name, meeting.host.host]) }}</span>
                                    </el-form-item>
                                </div>

                                <div class="fv-row mb-1">
                                    <label class="fs-6 fw-bold mb-2">{{ $t('pages.module.zoom.meeting.cols.approvalType') }}:</label>
                                    <el-form-item>
                                        <span class="fw-bold text-gray-700">{{ meeting.settings.approval_type ? $t('pages.module.zoom.meeting.approvalTypes.' + approvalTypes[meeting.settings.approval_type]) : "-" }}</span>
                                    </el-form-item>
                                </div>
                                <div class="fv-row mb-1">
                                    <label class="fs-6 fw-bold mb-2">{{ $t('pages.module.zoom.meeting.cols.duration') }}:</label>
                                    <el-form-item>
                                        <span class="fw-bold text-gray-700">{{ meeting.duration }}</span>
                                    </el-form-item>
                                </div>
                                <div class="fv-row mb-1">
                                    <label class="fs-6 fw-bold mb-2">{{ $t('pages.module.zoom.meeting.cols.startDate') }}:</label>
                                    <el-form-item>
                                        <span class="fw-bold text-gray-700">{{ $moment(meeting.start_date).format("DD.MM.YYYY - HH:mm:ss") }}</span>
                                    </el-form-item>
                                </div>
                                <div class="fv-row mb-1">
                                    <label class="fs-6 fw-bold mb-2">{{ $t('pages.module.zoom.meeting.detail.cols.endDate') }}:</label>
                                    <el-form-item>
                                        <span class="fw-bold text-gray-700">{{ meeting.end_date && $moment(meeting.end_date).format("DD.MM.YYYY - HH:mm:ss") }}</span>
                                    </el-form-item>
                                </div>
                                <div class="fv-row mb-1">
                                    <label class="fs-6 fw-bold mb-2">{{ $t('pages.module.zoom.meeting.cols.registrantCount') }}:</label>
                                    <el-form-item>
                                        <span class="fw-bold text-gray-700">{{ meeting.registrants_count }}</span>
                                    </el-form-item>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </el-form>
        </el-tab-pane>
        <el-tab-pane :label="$t('pages.module.zoom.meeting.detail.tabs.registrantSettings')" name="registrantSettings">
            <Registrant :meetingID="meetingID" v-if="tabModel == 'registrantSettings'"></Registrant>
        </el-tab-pane>
    </el-tabs>
    <Recording @recordingLoading="meetingRecordingLoading = $event"></Recording>
</template>

<script>
import Registrant from "@/components/module/zoom/meeting/Registrant";
import Recording from "@/components/module/zoom/meeting/recording";

export default {
    name: "_id",
    components: {
        Registrant,
        Recording
    },
    data(){
        return {
            tabModel: 'general',
            meeting: {
                settings: {}
            },
            approvalTypes: {1: 'manualApproval', 2: 'noApprovalRequired' },
            meetingRecordingLoading: false,
        }
    },
    computed: {
        meetingID() {
            return this.$route.params.id;
        },
    },
    created() {
        if (this.meetingID && !(this.meetingID > 0)) {
            this.$router.push({
                path: "/module/zoom/meeting"
            });
        }
    },
    mounted() {
        if (this.meetingID && this.meetingID > 0) {
            this.loadMeeting();
        }
    },
    methods: {
        loadMeeting() {
            this.axios.get(this.endpoints['module_zoom_meeting'] + '/' + this.meetingID).then((response) => {
                let data = response.data.data;

                if(data.settings == undefined || (data.settings && Array.isArray(data.settings))){
                    data.settings = {};
                }

                this.meeting = data;
            }).catch(error => {
                if(error.response.data.message == 'record_not_found'){
                    this.$router.push({
                        path: "/module/zoom/meeting"
                    });
                    return;
                }

                this.onResponseFailure(error.response.data);
            });
        },
        showMeetingRecording(){
            this.eventBus.$emit('showMeetingRecording', (this.meetingID));
        }
    }
}
</script>

<style>
.meetingForm .el-form-item__content {
    line-height: 1.5 !important;
}
</style>